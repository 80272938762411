import bsw from './img/bsw.png';
import betradar from './img/betradar.png';
import goldenRace from './img/golden-race.png';
import pragmatic from './img/pragmatic.png';
import evoplay from './img/evoplay.png';
import fiable from './img/fiable.png';
import evolution from './img/evolution.png';
import outcome from './img/outcome.png';
// import ezugi from './img/ezugi.png';
// import slotegrator from './img/slotegrator.png';
// import softgaming from './img/softgaming.png';
// import upgaming from './img/upgaming.png';
// import habanero from './img/habanero.png';
// import xpg from './img/xpg.png';
// import inbet from './img/inbet.png';
// import tvBet from './img/tvBet.png';

export const itemsOnPage = 10;

// BETRADAR

export const providerItems = [
  { value: 'all', name: 'All providers', active: true },
  { value: 'PRAGMATIC', name: 'Pragmatic', active: false, icon: pragmatic },
  { value: 'OUTCOMEBET', name: 'Outcomebet', active: false, icon: outcome },
  { value: 'FIABLE', name: 'Fiable', active: false, icon: fiable },
  { value: 'EVOPLAY', name: 'Evoplay', active: false, icon: evoplay },
  { value: 'BSWGAMES', name: 'Bsw', active: false, icon: bsw },
  { value: 'EVOLUTION', name: 'Evolution', active: false, icon: evolution },
  { value: 'GOLDENRACE', name: 'Golden Race', active: false, icon: goldenRace },
  // { value: 'EZUGI', name: 'Ezugi', active: false, icon: ezugi },
  { value: 'BETRADAR', name: 'Betradar', active: false, icon: betradar },

  // { value: 'HABANERO', name: 'Habanero', active: false, icon: habanero },
  // { value: 'SLOTEGRATOR', name: 'Slotegrator', active: false, icon: slotegrator },
  // { value: 'INBET', name: 'Inbet', active: false, icon: inbet },
  // { value: 'SOFTGAMING', name: 'Softgaming', active: false, icon: softgaming },
  // { value: 'UPGAMING', name: 'UPGAMING', active: false, icon: upgaming },
  // { value: 'XPG', name: 'Xpg', active: false, icon: xpg },
  // { value: 'TVBET', name: 'TVBet', active: false, icon: tvBet },
];
export const gameTypeItems = [
  { value: 'all', name: 'all', active: true },
  { value: 'casino', name: 'casino', active: false },
  { value: 'liveCasino', name: 'liveCasino', active: false },
  { value: 'virtualGames', name: 'virtualGames', active: false },
  /* { value: 'fiable', name: 'crashGames', active: false }, */
];
export const statusItems = [
  /* { value: 0, name: 'all', active: true }, */
  { value: 1, name: 'win', active: false },
  { value: 2, name: 'lose', active: false },
];
