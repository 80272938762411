import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import SVGInline from 'react-svg-inline';

import ArrowSVG from '../img/arrow.svg';

import './Paginator.scss';

const b = block('paginator');

const Paginator = ({ count, currentPage, onPageClick }) => {

  const isLeftDisabled = currentPage === 0;
  const isRightDisabled = currentPage === count;

  const onButtonClickHandler = isLeft => {
    if (isLeft && !isLeftDisabled) onPageClick(currentPage - 1);
    if (!isLeft && !isRightDisabled) onPageClick(currentPage + 1);
  }

  return (
    <div className={b()}>
      <div className={b('button', {disabled: isLeftDisabled})} onClick={() => onButtonClickHandler(true)}>
        <SVGInline className={b('arrow', {disabled: isLeftDisabled}).toString()} svg={ArrowSVG} />
      </div>
      <span className={b('value')}>{currentPage + 1}</span>
      <div
        className={b('button', {right: true, disabled: isRightDisabled})}
        onClick={() => onButtonClickHandler(false)}
      >
        <SVGInline className={b('arrow', {right: true, disabled: isRightDisabled}).toString()} svg={ArrowSVG} />
      </div>
    </div>
  );
};

Paginator.propTypes = {
  count: PropTypes.number.isRequired,
  currentPage: PropTypes.number.isRequired,

  onPageClick: PropTypes.func.isRequired,
};

export default Paginator;
