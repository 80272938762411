import React, { useState, useCallback, useMemo, useEffect } from 'react';
import block from 'bem-cn';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import SVGInline from 'react-svg-inline';
import dayjs from 'dayjs';

import { actions as transactionActions } from 'features/transaction/redux';

import Button from 'components/Button';
import InputDate from 'components/InputDate';

import './TransactionHistory.scss';

const b = block('transaction-history');

const TransactionHistory = () => {
  const dispatch = useDispatch();
  const [filterValue, onChangeFilterValue] = useState('');
  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const { transactions, total_deposit, total_withdrawal, actionProcessing } = useSelector(
    state => state.transaction,
    shallowEqual,
  );

  const [startDate, setStartDate] = useState(dayjs(new Date()).add(-3, 'day').$d);
  const [endDate, setEndDate] = useState(dayjs(new Date()).$d);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const onClick = useCallback(() => {
    const correctEndDate = dayjs(endDate).add(1, 'day').format('YYYY-MM-DD');
    dispatch(transactionActions.getTransactions({ startDate, endDate: correctEndDate }));
  }, [dispatch, endDate, startDate]);

  const list = useMemo(
    () =>
      transactions
        .sort((a, b) => (dayjs(b.date).isBefore(a.date) ? -1 : 1))
        .filter(t => ~t.email?.toUpperCase().indexOf(filterValue.toUpperCase()) || false)
        .map((item, index) => (
          <div className={b('row')}>
            <span className={b('td')}>{item.email}</span>
            <span className={b('td')}>
              {dayjs(+dayjs(item.date.toString().replaceAll('.', '-')).add(dayjs().utcOffset(), 'minute')).format(
                'YYYY.MM.DD HH:mm',
              )}
            </span>
            <span className={b('td')}>{item.amount}</span>
            <span className={b('td')}>{item.balance}</span>
          </div>
        )) || [],
    [filterValue, transactions],
  );

  console.log(list, transactions);

  return (
    <div className={b()}>
      <h3 className={b('title')}>{locale.transactionHistory[1]}</h3>
      <div className={b('content')}>
        <div className={b('fields')}>
          <div className={b('field-title')}>{`${locale.from}`}</div>
          <div className={b('field-title')}>{`${locale.to}`}</div>
          <div />
          <div className={b('field-input')}>
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(startDate)}
              onChange={date => {
                setStartDate(dayjs(date).format('YYYY-MM-DD'));
              }}
              isValid={Boolean(startDate)}
              title="date title"
            />
          </div>
          <div className={b('field-input')}>
            <InputDate
              dateFormat="yyyy-MM-dd"
              selected={new Date(endDate)}
              onChange={date => {
                setEndDate(dayjs(date).format('YYYY-MM-DD'));
              }}
              isValid={Boolean(endDate)}
              title="date title"
            />
          </div>
          <div className={b('button')}>
            <Button onClick={onClick}>{locale.display}</Button>
          </div>
        </div>
      </div>
      <div className={b('result-wrapper')}>
        <div className={b('result-header')}>
          <span className={b('td')}>{locale.email}</span>
          <span className={b('td')}>{locale.date}</span>
          <span className={b('td')}>{locale.amount}</span>
          <span className={b('td')}>{locale.balance}</span>
        </div>
        <div className={b('items')}>{list}</div>
      </div>
      {/* <div className={b('totals')}>
        <div className={b('totals-withdrawal')}>
          <p className={b('totals-withdrawal-title')}>{`${locale.totalWithdrawal}:`}</p>
          <p className={b('totals-withdrawal-value')}>{total_withdrawal}</p>
        </div>
        <div className={b('totals-topUp')}>
          <p className={b('totals-topUp-title')}>{`${locale.totalTopUp}:`}</p>
          <p className={b('totals-topUp-value')}>{total_deposit}</p>
        </div>
      </div> */}
    </div>
  );
};

export default TransactionHistory;
