import React from 'react';
import block from 'bem-cn';
import { Link, withRouter } from 'react-router-dom';
import { useSelector } from 'react-redux';

import logoPNG from 'shared/img/logo.png';
import { formatNumberWithLimit } from 'shared/helpers/formatNumber';

import './Header.scss';

const b = block('header');

const Header = () => {
  const locale = useSelector(state => state.locale.locale);
  const { isAuth, balance, currency, name, id } = useSelector(state => state.auth);

  return (
    <div className={b({ isAuth })}>
      {isAuth ? (
        <>
          <Link to="/" className={b('wrapper')}>
            <img className={b('logo')} src={logoPNG} alt="X10BET" />
          </Link>

          <div className={b('info')}>
            <div className={b('info-block')}>
              <p className={b('email')}>{name}</p>
              <p className={b('value')}>{`${formatNumberWithLimit(balance)} ${currency || 'BRL'}`}</p>
            </div>

            <div className={b('info-block')}>
              <p className={b('id')}>ID</p>
              <p className={b('value')}>{id}</p>
            </div>
          </div>
        </>
      ) :
        <h3 className={b('title')}>{locale.role[2]}</h3>}
    </div>
  );
};

export default withRouter(Header);
