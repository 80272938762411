import BaseApi from '../BaseApi';
import { WithdrawalConverter, MOCK } from './WithdrawalConverter';

class WithdrawalApi extends BaseApi {
  constructor(baseUrl) {
    super(baseUrl);
    this.baseUrl = `${baseUrl}/api/mini_admin`;
    this.converter = new WithdrawalConverter();
  }

  getWithdrawalRequests = args => {
    // return this.sendQueryMock(this.converter.convertWithdrawalRequests, MOCK);

    return this.sendQuery(
      this.queryTypes.GET,
      `${this.baseUrl}/cashier_withdrawal_by_payment`,
      { ...args },
      null,
      this.converter.convertWithdrawalRequests,
    );
  };

  resolveCard = ({ id, status, amount, userId }) => {
    return this.sendQuery(
      this.queryTypes.PATCH,
      `${this.baseUrl}/cashier_withdrawal_by_payment`,
      {
        id: Number(id),
        status: Number(status),
        // user_id: Number(userId),
      },
      null,
    );
  };
}

export default WithdrawalApi;
