import React, { useState, useCallback } from 'react';
import block from 'bem-cn';
import SVGInline from 'react-svg-inline';
import { useSelector, useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';

import Input from 'components/Input';
import InputPhone from 'components/InputPhone';
import { phoneCodes } from 'components/InputPhone/data';
import Button from 'components/Button';

import { actions as usersActions } from 'features/users/redux';

import './CreateUser.scss';

const b = block('create-user');

const CreateUser = ({ history }) => {
  const dispatch = useDispatch();

  const [phone, changePhone] = useState('');
  const [phoneCode, changePhoneCode] = useState('PERU');
  const [password, changePassword] = useState('');

  const role = useSelector(state => state.auth.role);
  const locale = useSelector(state => state.locale.locale);
  const actionProcessing = useSelector(state => state.users.actionProcessing);

  const onClick = useCallback(() => {
    if (!actionProcessing)
      dispatch(
        usersActions.createUser(phone, password, userId =>
          history.replace('/'),
        ),
      );
  }, [actionProcessing, dispatch, password, phoneCode, phone, role, history]);

  let headerText = locale[`newAgent${role}`];

  return (
    <div className={b()}>
      <div className={b('content')}>
        <h3 className={b('title')}>{headerText.split(' - ')[1]}</h3>
        <div className={b('field')}>
          <div className={b('field-title')}>{locale.userName}</div>
          <div className={b('field-input')}>
            <Input placeholder={locale.userName} value={phone} onChange={e => changePhone(e.currentTarget.value)} />
          </div>
        </div>
        <div className={b('field')}>
          <div className={b('field-title')}>{locale.password}</div>
          <div className={b('field-input')}>
            <Input
              placeholder={locale.password}
              value={password}
              onChange={e => changePassword(e.currentTarget.value)}
            />
          </div>
        </div>
        <div className={b('button')}>
          <Button onClick={onClick}>
            <span className={b('button-text')}>{locale.createAccount}</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default withRouter(CreateUser);
