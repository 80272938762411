import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changeLang: 'Cambiar idioma',
  changePas: 'Cambia la contraseña',
  logout: 'Cerrar sesión',
  other: 'Otra',
  successAuth: 'Autorización de éxito',
  notRules: 'Privilegios de inicio de sesión insuficientes',

  newPlayer: 'Nuevo jugador',
  userName: 'Nombre de usuario',
  password: 'Clave',
  createAccount: 'Crear una cuenta',
  /* '/user-create': 'Caja registradora - nuevo agente ',*/
  '/user-create-1': 'Caja registradora - Jugador nuevo',
  '/user-create-2': 'Caja registradora - nuevo agente',
  '/user-create-3': 'Caja registradora - nuevo administrador',
  '/total': 'Caja registradora: transacciones de cajero',
  '/transaction-history': 'Caja registradora - historial de transacciones',
  '/': 'Menú',
  '/locale': 'Lenguaje',
  '/user-edit': 'Caja registradora - edición de usuarios',
  '/change-pas': 'Caja registradora: cambiar contraseña',
  '/cashier-jackpot': 'Caja registradora - Jackpot de cajero',
  '/users-list': 'Caja registradora - Saldos de tarjetas de usuario',
  '/totalBalance': 'balance',
  '/tree': 'Árbol',
  userCardBalances: 'Saldos de tarjetas de usuarios',
  userCard: 'Tarjeta de usuario',
  accountBalance: 'Saldo de la cuenta',
  nickname: 'Apodo',
  id: 'Identificación',
  betsMenu: 'Apuestas',
  balance: 'balance',
  ban: 'Ban',
  banSuccess: 'Prohibir el éxito',
  unbanSuccess: 'Éxito no',
  unban: 'Alma',
  resetPassword: 'Restablecer la contraseña',
  resetSuccess: 'Restablecer el éxito de la contraseña',
  networkError: 'Error de red',
  createSuccess: 'Crear éxito',
  topUp: 'Topup',
  page: 'Página',
  withdrawal: 'Retiro',
  topUpSuccess: 'Éxito de topup',
  withdrawalSuccess: 'Éxito de retiro',
  topUpWithdrawal: 'Topup/retiro',
  ok: 'OK',
  theNewPassword: 'La nueva contraseña',
  for: 'por',
  all: 'Todos',
  '/transaction-history': 'Socios - Historial de transacciones',
  startDate: 'Fecha de inicio',
  endingDate: 'Fecha de finalización',
  display: 'Monitora',
  email: 'Correo electrónico',
  date: 'Fecha',
  amount: 'Monto',
  languageSelection: 'Selección de idioma',
  login: 'Acceso',

  betTypes: {
    all: 'Todos los tipos',
    bet: 'Apuesta',
    win: 'Victoria',
    returned: 'Devuelto',
    cashedOut: 'Sin efectivo',
    cashed_out: 'Sin efectivo',
    lost: 'Perdió',
    rollback: 'Retroceder',
    refund: 'Reembolso',
  },
  betType: 'Tipo de apuesta',
  selectPeriod: 'Período de selección',
  from: 'De',
  to: 'A',
  apply: 'Aplicar',
  userId: 'ID de usuario:',
  betTableHeads: {
    id: 'Identificación',
    type: 'Escribe',
    fromUserId: 'De ID de usuario',
    toUserId: 'A ID de usuario',
    value: 'Valor',
    currency: 'Divisa',
    note: 'Nota',
    createdAt: 'Creado en',
    description: 'Descripción',
    balanceBefore: 'Equilibrar antes',
  },
  noBets: 'Sin apuestas',

  cashRegister: 'Caja registradora',
  createUser: {
    1: 'Nuevo jugador',
    2: 'Nuevo agente',
    3: 'Nuevo administrador',
  },
  balances: {
    1: 'Saldos de tarjetas de usuario',
    2: 'Saldos de las cartas de la tienda',
    3: 'Saldos de tarjetas de administración',
  },
  cashiersTransactions: {
    1: 'Transacciones de cajero',
    2: 'Transacciones de tiendas',
    3: 'Transacciones de administradores',
  },
  bets: {
    1: 'Apuestas',
    2: 'Apuestas',
    3: 'Apuestas',
  },
  partners: 'Socias',
  revenuBalances: 'Equilibrar ingresos',
  transactionHistory: {
    1: 'Historial de transacciones',
    2: 'Historial de transacciones',
    3: 'Historial de transacciones',
  },
  cashierJackpot: {
    1: 'Jackpot de cajero',
    2: 'Jackpot de cajero',
    3: 'Jackpot de cajero',
    7: 'Jackpot de cajero',
  },
  totalBalance: {
    1: 'balance',
    2: 'balance',
    3: 'balance',
    7: 'balance',
  },
  tree: {
    1: 'Árbol',
    2: 'Árbol',
    3: 'Árbol',
    7: 'Árbol',
  },
  casinoHistory: {
    1: 'Historia del casino',
    2: 'Historia del casino',
    3: 'Historia del casino',
    7: 'Historia del casino',
  },
  casinoHistory2: 'Historia del casino',

  total: 'total',
  term: 'Término',
  deposit: 'Depósito',
  withdraw: 'Retirar',
  cashPayment: 'Pago en efectivo',
  cashPayout: 'Pago en efectivo',
  search: 'Búsqueda',
  errorPassword:
    'La contraseña debe consistir en: ¡al menos 8 caracteres, al menos un número, una cartera superior y minúscula!',
  noPass: '¡La contraseña no debe estar vacía!',
  dataUpdate: 'Los datos se actualizan una vez cada 12 horas. Por lo tanto, los datos para el día actual pueden ser incorrectos.',
  treeMenu: 'Árbol',

  players: 'Jugadoras',
  subusers: 'Sub-usuarios',

  jackpotWinnersList: 'Lista de ganadores del Jackpot',
  startAmount: 'Inicio de la cantidad',

  winnerID: 'ID de ganador',
  winDate: 'Fecha de ganar',
  amountOfTheWinningJackpot: 'Cantidad del premio mayor ganador',

  totalWithdrawal: 'Retiro total',
  totalTopUp: 'Depósito total',

  provider: 'Proveedora',
  gameType: 'Tipo de juego',

  newAgent1: 'Caja registradora - Jugador nuevo',
  newAgent2: 'Caja registradora - nuevo agente',
  newAgent3: 'Caja registradora - nuevo administrador',

  home: 'Hogar',
  changeLanguage: 'Cambiar idioma',
  changePassword: 'Cambia la contraseña',
  changedPasswordDoNotMatch: 'Las contraseñas no coinciden',
  oldPassword: 'Contraseña anterior',
  newPassword: 'Nueva contraseña',
  confirmNewPassword: 'Confirmar nueva contraseña',
  enterOldPassword: 'Ingrese la contraseña antigua',
  enterNewPassword: 'Ingrese nueva clave',
  repeatNewPassword: 'repita la nueva contraseña',
  save: 'Ahorrar',

  typeSomething: 'Escribe algo',

  userEdit: 'Caja registradora - edición de usuarios',

  userId: 'ID de usuario',
  showResults: 'Mostrar resultados',
  bet: 'apuesta',
  outcome: 'Salir',
  fail: 'apuesta',

  role: {
    0: 'Jugadora',
    1: 'Cajera',
    2: 'Administración',
    3: 'suadmin',
  },

  'The password can contain only letters and numbers': 'La contraseña puede contener solo letras y números',
  error: 'Error',
  lost: 'Perdió',

  wantLogout: '¿Estás segura de que quieres cerrar sesión?',
  yesLogout: 'Sí, cerrar sesión',
  noIStay: "No, me quedo aquí.",
  welcomeToPanel: '¡Bienvenido al panel de administración!',
  useLeftMenu: 'Use el menú de la izquierda para administrar usuarios',
  adminPanel: 'Panel de administrador',
  userData: 'Datos del usuario',

  withdrawalRequests: {
    1: 'solicitudes de retiro',
    2: 'solicitudes de retiro',
    3: 'solicitudes de retiro',
    7: 'solicitudes de retiro',
  },
  status: 'Estado',
  playerId: 'Jugador ID',
  phone: 'Teléfono',
  edit: 'Editar',
  request: 'Solicitud',
  approve: 'Aprobar',
  decline: 'Rechazar',
  nameOrId: 'Escriba inicio de sesión o ID',
  userId: 'ID de usuario',
};
