import React, { useMemo } from 'react';
import block from 'bem-cn';
import { shallowEqual, useSelector } from 'react-redux';

import InputDate from 'components/InputDate';
import Input from 'components/Input';
import Button from 'components/Button';
import Select from 'components/Select';
import CheckBox from 'components/CheckBox';

import './SlotsHistoryFilter.scss';

const b = block('slots-bet-history-filter-desktop');

const SlotsHistoryFilter = ({ filterData }) => {
  const locale = useSelector(state => state.locale.locale, shallowEqual);

  const {
    state,
    providerItems,
    gameTypeItems,
    statusItems,
    onChangeGameType,
    onChangeProvider,
    onChangeStatus,
    onChangeFrom,
    onChangeTo,
    onSubmit,
    onChangeUserId,
  } = filterData;

  return (
    <form className={b()} onSubmit={onSubmit}>
      <div className={b('items')}>
        <span className={b('label')}>{locale.userId}</span>
        <span className={b('label')}>{locale.gameType}</span>
        <span className={b('label')}>{locale.provider}</span>
        <span className={b('label')}>{locale.from}</span>
        <span className={b('label')}>{locale.to}</span>
        <span />

        <div className={b('item-double-input')}>
          <Input value={state.userId} onChange={onChangeUserId} type="number" placeholder={locale.userId} />
        </div>
        <div className={b('item-double-input')}>
          <Select
            items={gameTypeItems}
            activeItem={state.gameTypes.find(p => p.active) || state.gameTypes[0]}
            placeholder={locale.allGameTypes}
            onChange={value => {
              const updatedItems = state.gameTypes.map(p => ({ ...p, active: p.value === value }));
              onChangeGameType(updatedItems);
            }}
          />
        </div>
        <div className={b('item-selector-input')}>
          <Select
            items={providerItems}
            activeItem={state.providers.find(p => p.active) || state.providers[0]}
            placeholder={locale.allProviders}
            onChange={value => {
              const updatedItems = state.providers.map(p => ({ ...p, active: p.value === value }));
              onChangeProvider(updatedItems);
            }}
          />
        </div>
        <div className={b('item-dates-input')}>
          <InputDate dateFormat="yyyy-MM-dd" selected={new Date(state.from)} onChange={onChangeFrom} />
        </div>
        <div className={b('item-dates-input')}>
          <InputDate dateFormat="yyyy-MM-dd" selected={new Date(state.to)} onChange={onChangeTo} />
        </div>
        <div className={b('button')}>
          <Button type="submit">{locale.showResults}</Button>
        </div>
      </div>
    </form>
  );
};

SlotsHistoryFilter.propTypes = {};

export default SlotsHistoryFilter;
