import React, { useEffect, useState, useMemo, useCallback } from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import SVGInline from 'react-svg-inline';
import { Link } from 'react-router-dom';

import { actions as usersActions } from 'features/users/redux';

import InputSearch from 'components/InputSearch';
import SVG from 'components/SVG';
import Button from 'components/Button';
import Paginator from 'components/Paginator/desktop';

import arrowSvg from './img/arrow.svg';

import './UsersList.scss';

const b = block('users-list');
const itemsOnPage = 30;

const UsersList = () => {
  const [filterValue, onChangeFilterValue] = useState('');
  const [items, setItems] = useState([]);
  const [page, setPage] = useState(0);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(usersActions.getUsersList());
  }, [dispatch]);

  const changeFilterValue = useCallback(e => {
    onChangeFilterValue(e.currentTarget.value);
  }, []);

  const handleFromSubmit = useCallback(e => {
    e.preventDefault();
    setItems(usersList.users?.filter(t =>
      t?.nickname.toUpperCase().includes(filterValue.toUpperCase())
      || String(t?.id).includes(filterValue)));
  }, [filterValue]);

  const locale = useSelector(state => state.locale.locale, shallowEqual);
  const usersList = useSelector(state => state.users.usersList, shallowEqual);

  useEffect(() => {
    setItems(usersList.users);
    onChangeFilterValue('');
  }, [usersList]);

  const list = useMemo(() => items
    .slice(itemsOnPage * page, itemsOnPage * (page + 1))
    .map(item => (
      <Link key={item.id} className={b('item')} to={`/user-edit/${item.id}`}>
        <div className={b('item-text')}>{`${item.nickname} (ID: ${item.id})`}</div>
        <div className={b('item-balance')}>{item.balance}</div>
        <SVG svgProps={{ svg: arrowSvg }} className={b('item-arrow')} />
      </Link>
    )) || [],
  [filterValue, items, page]);

  const pageCount = Math.ceil(items / itemsOnPage);

  return (
    <div className={b()}>
      <h3 className={b('title')}>{locale.userCardBalances}</h3>
      <form className={b('filter')} onSubmit={handleFromSubmit}>
        <div className={b('search')}>
          <p className={b('label')}>{locale.search}</p>
          <div className={b('search-input')}>
            <InputSearch value={filterValue} onChange={changeFilterValue} placeholder={locale.search} />
          </div>
        </div>
        <div className={b('button')}>
          <Button type="submit">
            <div className={b('button-text')}>{locale.showResults}</div>
          </Button>
        </div>
        <div className={b('subheader-data-item')}>
          <div className={b('subheader-data-item-nick')}>{locale.userCard}</div>
          <div className={b('subheader-data-item-value')}>{usersList.quantity}</div>
        </div>
        <div className={b('subheader-data-item')}>
          <div className={b('subheader-data-item-nick')}>{locale.accountBalance}</div>
          <div className={b('subheader-data-item-value')}>{usersList.totalBalance}</div>
        </div>
      </form>
      <div className={b('list')}>{list}</div>
      <div className={b('paginator')}>
        <Paginator
          count={pageCount}
          currentPage={page}
          onPageClick={setPage}
        />
      </div>
    </div>
  );
};

export default UsersList;
