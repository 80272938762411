import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import { itemsOnPage } from 'features/slotsBetHistory/helpers/data';

dayjs.extend(customParseFormat);

export const MOCK = {
  data: [
    {
      date: '2022-05-27 16:00:32',
      amount: 2.32,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:17',
      amount: 2.32,
      status: 1,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:17',
      amount: 2.32,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:01',
      amount: 2.32,
      status: 1,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:01',
      amount: 2.32,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 15:59:45',
      amount: 6,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 15:59:34',
      amount: 6,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-26 18:31:51',
      amount: 1,
      status: 2,
      provider: 'BSW',
    },
    {
      date: '2022-05-26 18:30:27',
      amount: 2,
      status: 1,
      provider: 'BSW',
    },
    {
      date: '2022-05-26 18:30:23',
      amount: 1,
      status: 2,
      provider: 'BSW',
    },
    {
      date: '2022-05-27 16:00:32',
      amount: 2.32,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:17',
      amount: 2.32,
      status: 1,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:17',
      amount: 2.32,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:01',
      amount: 2.32,
      status: 1,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:01',
      amount: 2.32,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 15:59:45',
      amount: 6,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 15:59:34',
      amount: 6,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-26 18:31:51',
      amount: 1,
      status: 2,
      provider: 'BSW',
    },
    {
      date: '2022-05-26 18:30:27',
      amount: 2,
      status: 1,
      provider: 'BSW',
    },
    {
      date: '2022-05-26 18:30:23',
      amount: 1,
      status: 2,
      provider: 'BSW',
    },
    {
      date: '2022-05-27 16:00:32',
      amount: 2.32,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:17',
      amount: 2.32,
      status: 1,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:17',
      amount: 2.32,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:01',
      amount: 2.32,
      status: 1,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 16:00:01',
      amount: 2.32,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 15:59:45',
      amount: 6,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-27 15:59:34',
      amount: 6,
      status: 2,
      provider: 'PRAGMATIC',
    },
    {
      date: '2022-05-26 18:31:51',
      amount: 1,
      status: 2,
      provider: 'BSW',
    },
    {
      date: '2022-05-26 18:30:27',
      amount: 2,
      status: 1,
      provider: 'BSW',
    },
    {
      date: '2022-05-26 18:30:23',
      amount: 1,
      status: 2,
      provider: 'BSW',
    },
  ],
  count: 77,
};

export class SlotsBetHistoryConverter {
  convertSlotsBetHistory = d => {
    return {
      list:
        d?.data?.map(t => ({
          amount: t.amount,
          balanceBefore: t.balanceBefore,
          date: dayjs(t.date, 'YYYY-MM-DD HH:mm:ss').format('MMMM D, HH:mm'),
          gameType: t.gameType,
          id: t.id,
          provider: t.provider,
          status: t.status,
          userId: t.userId,
          /* date: t.date,
          amount: t.amount,
          status: t.status,
          provider: t.provider,
          gameType: t.gameType, */
        })) || [],
      pages: Math.ceil(d?.totalCount / itemsOnPage) /* Math.ceil(d?.count / itemsOnPage) */,
    };
  };
}
