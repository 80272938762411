import EnPNG from './img/en.png';
import EsPNG from './img/es.png';
import PtPNG from './img/pt.png';


export const languages = {
  PT: 'pt-pt',
  EN: 'en-US',
  ES: 'es-es',
};

export const languagesWithIcons = {
  [languages.PT]: { lang: languages.PT, icon: PtPNG, text: 'Brazilian' },
  [languages.ES]: { lang: languages.ES, icon: EsPNG, text: 'Español' },
  [languages.EN]: { lang: languages.EN, icon: EnPNG, text: 'English' },
};

export const shortLanguages = {
  [languages.EN]: 'en',
  [languages.ES]: 'es',
  [languages.PT]: 'pt',
};

const rtlLangs = [];

export const checkIsRtlLang = lang => {
  return rtlLangs.includes(lang);
}
