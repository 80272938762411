import dayjs from 'dayjs';

import { itemsOnPage } from 'features/withdrawal/helpers/data';

export const MOCK = {
  data: [
    {
      "id":37,
      "player_id":6,
      "amount":1000,
      "status":0,
      "create_date":"2022-08-29 13:43:58",
      "requisite":"123123123",
      "phone":"12312312",
    },
    {
      "id":12,
      "player_id":3,
      "amount":200,
      "status":3,
      "create_date":"2022-08-29 13:43:58",
      "requisite":"123123123",
      "phone":"123asdasd12312",
    },
    {
      "id":333,
      "player_id":23,
      "amount":234,
      "status":2,
      "create_date":"2022-08-29 13:43:58",
      "requisite":"123123123",
      "phone":"qweqwe",
    },
  ],
  count: 77,
};

export class WithdrawalConverter {
  convertWithdrawalRequests = d => {

    return {
      list:
        d
          ?.sort((a, b) => dayjs(b.create_date) - dayjs(a.create_date))
          ?.map(t => ({
            id: t.id,
            playerId: t.player_id,
            amount: t.amount,
            status: t.status,
            date: dayjs(t.create_date).format('YYYY-MM-DD HH:mm:ss'),
            requisite: t.requisite,
            phone: t.phone,
          })) || [],
      pages: Math.ceil(d?.length / itemsOnPage),
    };
  };
}
