import React from 'react';
import block from 'bem-cn';
import { useSelector, useDispatch } from 'react-redux';

import Button from 'components/Button';
import PopUp from 'components/PopUp';
import { actions as authActions } from 'features/Auth';

import './AuthModal.scss';

const b = block('auth-modal');

const AuthModal = () => {
  const dispatch = useDispatch();
  const locale = useSelector(state => state.locale.locale);

  const handleLogOut = () => {
    dispatch(authActions.logOut());
    dispatch(authActions.setAuthModal(false));
  }

  const logoutContent =
    <section className={b()}>
      <h3 className={b('pop-up-title')}>{locale.wantLogout}</h3>
      <div className={b('buttons')}>
        <div className={b('pop-up-button')}>
          <Button color="bordered" onClick={handleLogOut}>
            <div className={b('pop-up-button-text')}>
              {locale.yesLogout}
            </div>
          </Button>
        </div>
        <div className={b('pop-up-button')}>
          <Button onClick={() => dispatch(authActions.setAuthModal(false))}>
            <div className={b('pop-up-button-text')}>
              {locale.noIStay}
            </div>
          </Button>
        </div>
      </div>
    </section>


  return (
    <PopUp
      title={locale.logout}
      onClose={() => dispatch(authActions.setAuthModal(false))}
      withBG
    >
      {logoutContent}
    </PopUp>
  );
};

export default AuthModal;
